// Note: Requires flexslider 2, buddy.
jQuery(document).ready(function($) {
	/********************************
	 THREE BY SLIDER
	*********************************/
	// store the slider in a local variable
	// var $window = $(window),
	// 	flexslider = { vars:{} 
	// };

	// // tiny helper function to add breakpoints
	// // Basically if the screen on resize is less than 600px wide, then the minItems is 1. Otherwise, 3.
	// function getGridSize() {
	// 	// return (window.innerWidth < 1200) ? 2 : 3;
	// 	return (window.innerWidth < 768) ? 1 :
    //        (window.innerWidth < 1200) ? 2 : 3;
	// }
	 
	// $window.load(function() {
	// 	$('.three-by-slider-wrapper .flexslider').flexslider({
	// 		animation: "slide",
	// 		prevText: "Previous",
	// 		nextText: "Next",
	// 		controlNav: false,
	// 		directionNav: true,
	// 		itemWidth: 444,
	// 		itemMargin: 34,
	// 		minItems: getGridSize(), // use function to pull in initial value
	// 		maxItems: getGridSize() // use function to pull in initial value
	// 	});
	//   });
	 
	//   // check grid size on resize event
	//   $window.resize(function() {
	//     var gridSize = getGridSize();
	// 	console.log(gridSize);
	 
	//     flexslider.vars.minItems = gridSize;
	//     flexslider.vars.maxItems = gridSize;
	//   });
//replace the flexslider with a slick slider
	$('.three-by-slider-wrapper .slides').slick({
	slidesToShow: 3,
	slidesToScroll: 3,
	autoplay: true,
	autoplaySpeed: 5000,
	arrows: true,
	dots: false,
	infinite: true,
	nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><span class="screen-reader-text">Next</span></button>',
	prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><span class="screen-reader-text">Previous</span></button>',
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1, 
				slidesToScroll: 1
			}
		}
	]
	});

}); /* end of as page load scripts */